.guide-container .P14,
.guide-container .P14-Bold{
    font-size: 16px;
    line-height: 30px;
}
.guide-container .P16,
.guide-container .P16-Bold,
.guide-container .H200-Bold,
.guide-container .H200-Regular {
    font-size: 18px;
    line-height: 30px;
}



