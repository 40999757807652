
:root{
    --sans-regular: NotoSans-Regular;
    --sans-semibold: NotoSans-SemiBold;
    --sans-bold: NotoSans-Bold;

    --JM-Background: #F9F9FA;
    --JM-Utility: #9747FF;

    --JM-Blue: #154B7C;
    --JM-Blue-75: #63779B;
    --JM-Blue-50: #8498B5;
    --JM-Blue-25: #C8D2DD;
    --JM-Blue-15: #E3E8ED;

    --JM-Green: #567D54;
    --JM-Green-75: #85A785;
    --JM-Green-50: #AEC4AE;
    --JM-Green-25: #D1DCD1;
    --JM-Green-15: #E5E9E2;

    --JM-Grey: #28282A;
    --JM-Grey-75: #5E5E5F;
    --JM-Grey-50: #949495;
    --JM-Grey-25: #C9C9C9;
    --JM-Grey-15: #DFDFDF;
    --JM-Grey-5: #EAEAEA;

    --JM-Yellow: #F7DC1C;
    --JM-Yellow-75: #FAEB7F;
    --JM-Yellow-50: #FDF8D2;

    --JM-Orange: #F89520;
    --JM-Orange-50: #F8E0C0;

    --JM-Red: #DC3545;
    --JM-Red-50: #F2C5CA;

    --JM-LightBlue: #63BAE9;
    --JM-LightBlue-50: #BADCF1;
}

body{
    font-family: var(--sans-regular);
    line-height: 30px;
}

.bg-jm-blue{
    background-color: var(--JM-Blue);
}

.bg-jm-blue-75{
    background-color: var(--JM-Blue-75);
}

.w-full{
    width: 100%;
}

.relative, .relative > *{
    position: relative;
}

.float-left, .float-left > *{
    float: left;
}

.sticky{
    position: sticky;
}

.top-0{
    top: 0;
}

.z-99{
    z-index: 99;
}

.py-5{
    padding-top: 20px;
    padding-bottom: 20px;
}

.pb-5{
    padding-bottom: 20px;
}

.mt-0{
    margin-top: 0;
}

.mb-4{
    margin-bottom: 16px;
}

.mb-2-5{
    margin-bottom: 10px;
}

.mb-5{
    margin-bottom: 20px;
}

.mb-6{
    margin-bottom: 24px;
}

.px-4{
    padding-left: 16px;
    padding-right: 16px;
}

.bg-separator{
    height: 500px;
}

.hidden{
    display: none
}

.underline{
    text-decoration: underline;
}

.italic{
    font-style:italic;
}

.text-lg{
    font-size: 20px;
}

.text-base{
    font-size: 16px;
}

.font-normal{
    font-weight: normal;
}

.flex-wrap{
    flex-wrap:wrap;
}

.bg-gray{
    background-color: #F4F4F6;
}

.border-box{
    box-sizing: border-box;
}

.leading-normal{
    line-height: 1.5;
}