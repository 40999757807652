@font-face {
  font-family: Roboto-BlackItalic;
  src: local("Roboto Black Italic"), local("Roboto-BlackItalic"),
    url("./fonts/Roboto-BlackItalic.woff") format("woff"),
    url("./fonts/Roboto-BlackItalic.woff2") format("woff2");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Roboto-Bold;
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("./fonts/Roboto-Bold.woff") format("woff"),
    url("./fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"),
    url("./fonts/Roboto-BoldItalic.woff") format("woff"),
    url("./fonts/Roboto-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Roboto-LightItalic;
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"),
    url("./fonts/Roboto-LightItalic.woff") format("woff"),
    url("./fonts/Roboto-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Roboto-Regular;
  src: local("Roboto"), local("Roboto-Regular"),
    url("./fonts/Roboto-Regular.woff") format("woff"),
    url("./fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: local("Roboto Thin Italic"), local("Roboto-ThinItalic"),
    url("./fonts/Roboto-ThinItalic.woff") format("woff"),
    url("./fonts/Roboto-ThinItalic.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Roboto-Black;
  src: local("Roboto Black"), local("Roboto-Black"),
    url("./fonts/Roboto-Black.woff") format("woff"),
    url("./fonts/Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"),
    url("./fonts/Roboto-MediumItalic.woff") format("woff"),
    url("./fonts/Roboto-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Roboto-Thin;
  src: local("Roboto Thin"), local("Roboto-Thin"),
    url("./fonts/Roboto-Thin.woff") format("woff"),
    url("./fonts/Roboto-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Roboto-Light;
  src: local("Roboto Light"), local("Roboto-Light"),
    url("./fonts/Roboto-Light.woff") format("woff"),
    url("./fonts/Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto-Italic;
  src: local("Roboto Italic"), local("Roboto-Italic"),
    url("./fonts/Roboto-Italic.woff") format("woff"),
    url("./fonts/Roboto-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Roboto-Medium;
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("./fonts/Roboto-Medium.woff") format("woff"),
    url("./fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: NotoSans-Regular;
  src: url("fonts/NotoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: NotoSans-SemiBold;
  src: url("fonts/NotoSans-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: NotoSans-Bold;
  src: url("fonts/NotoSans-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

.H900-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 48px;
}

.H900-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 48px;
}

.H900-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 48px;
}

.H800-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 40px;
}

.H800-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 40px;
}

.H800-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 40px;
}

.H700-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 31px;
  line-height: 36px;
}

.H700-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 31px;
  line-height: 36px;
}

.H700-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 31px;
  line-height: 36px;
}

.H600-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 32px;
}

.H600-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}

.H600-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
}

.H500-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
}

.H500-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.H500-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

.H400-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}

.H400-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.H400-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.H300-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.H300-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.H300-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.H200-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
}

.H200-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.H200-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.H100-Black {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
}

.H100-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.H100-Regular {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.P21 {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */
  letter-spacing: -0.24px;
}

.P16 {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* or 150% */
  letter-spacing: -0.24px;
}

.P16-Bold {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  /* or 150% */
  letter-spacing: -0.24px;
}

.P14-Bold {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* or 150% */
  letter-spacing: -0.24px;
}

.P14 {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 150% */
  letter-spacing: -0.24px;
}

.P12 {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.24px;
}

.C-H-1 {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.C-H-2 {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 125% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.C-H-3 {
  font-family: var(--sans-bold);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 125% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.small400 {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: var(--neutral_70);
}

.small400-bold {
  font-family: var(--sans-regular);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: var(--neutral_70);
}
